import React from 'react';
import UploadPage from './UploadPage';
import './App.css'; // Import CSS file for styles
import logo from './logo.svg'; // Import your logo

function App() {
  return (
    <>
      <div className="App">
        <div className="logo-container">
          <img className="logo-image" src={logo} alt="Logo" />
        </div>
        <UploadPage />
      </div>
    </>
  );
}

export default App;
